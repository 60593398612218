import {FC} from 'react';

interface WaywardProps {
  width?: number;
  height?: number;
}

export const Wayward: FC<WaywardProps> = ({width = 139, height = 32}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 139 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.2694 25.7077C59.9804 26.0299 60.8184 26.1896 61.7821 26.1896C62.5433 26.1896 63.238 26.0876 63.8632 25.8851C64.4899 25.6648 65.0471 25.3604 65.5393 24.9716C65.7359 24.8091 65.9221 24.6302 66.098 24.4381V25.8866H69.2462V16.9991C69.2462 16.0176 69.0009 15.1633 68.5102 14.4347C68.0357 13.6897 67.3677 13.1148 66.5045 12.7083C65.6413 12.2856 64.5165 12.0742 63.3828 12.0742H60.1888L59.1187 14.9165H63.3828C64.144 14.9165 64.753 15.119 65.2112 15.5255C65.6856 15.9142 65.9221 16.4049 65.9221 16.9976V17.1424L61.8102 17.812C60.7268 17.9805 59.8296 18.2687 59.1202 18.6752C58.4092 19.0816 57.8845 19.5886 57.546 20.1976C57.2076 20.7903 57.0391 21.4672 57.0391 22.2284C57.0391 22.9896 57.2342 23.7257 57.6229 24.3346C58.0116 24.9273 58.5615 25.384 59.2724 25.7062L59.2694 25.7077ZM65.9206 19.6847L62.4428 20.2759C61.7821 20.3941 61.2914 20.6055 60.9707 20.91C60.6485 21.2145 60.4888 21.612 60.4888 22.1028C60.4888 22.5935 60.6662 22.9571 61.0224 23.2453C61.3949 23.5158 61.8516 23.6517 62.3925 23.6517C63.0857 23.6517 63.6962 23.5084 64.2209 23.2202C64.7619 22.9157 65.1772 22.5004 65.4639 21.9771C65.7684 21.4524 65.9206 20.8775 65.9206 20.2508V19.6847Z"
      fill="#111111"
    />
    <path
      d="M119.28 16.2453C119.28 15.5136 119.873 14.9209 120.605 14.9209H122.941L124.016 12.0742H120.093C117.807 12.0742 115.953 13.9277 115.953 16.2142V25.8851H119.279V16.2453H119.28Z"
      fill="#111111"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130.291 26.2194C128.993 26.2194 127.836 25.9045 126.816 25.2734C125.796 24.6438 124.99 23.7821 124.398 22.6883C123.805 21.5946 123.508 20.353 123.508 18.9636C123.508 17.5743 123.805 16.3416 124.398 15.2656C124.99 14.1718 125.797 13.319 126.816 12.7085C127.836 12.0789 128.993 11.7641 130.291 11.7641C131.328 11.7641 132.237 11.9592 133.015 12.3479C133.794 12.7366 134.423 13.2835 134.905 13.9885V5.87109H138.463V25.8868H134.905V23.9136C134.46 24.5255 133.867 25.062 133.125 25.5261C132.403 25.9888 131.458 26.2208 130.291 26.2208V26.2194ZM131.041 23.1051C132.19 23.1051 133.127 22.7253 133.85 21.9655C134.592 21.1866 134.961 20.1963 134.961 18.9902C134.961 17.7841 134.59 16.8027 133.85 16.043C133.127 15.2641 132.191 14.8753 131.041 14.8753C129.891 14.8753 128.975 15.2552 128.233 16.0149C127.491 16.7746 127.122 17.7575 127.122 18.9621C127.122 20.1668 127.493 21.1585 128.233 21.9375C128.975 22.7164 129.911 23.1051 131.041 23.1051Z"
      fill="#111111"
    />
    <path
      d="M68.8516 12.0957H72.4048L75.757 21.1044L79.1092 12.0957H82.6624L75.2648 32.0005H71.7027L73.9745 25.8844L68.8516 12.0957Z"
      fill="#111111"
    />
    <path
      d="M84.2407 25.8844L81.7812 19.2642L83.5593 14.4828L86.0232 21.1044L89.3754 12.0957H92.9523L96.3045 21.1044L99.6567 12.0957H103.21L98.0871 25.8844H94.522L91.1639 16.8476L87.8058 25.8844H84.2407Z"
      fill="#111111"
    />
    <path
      d="M34.3594 12.0957H37.9126L41.2648 21.1044L44.617 12.0957H48.1939L51.5461 21.1044L54.8983 12.0957H58.4515L53.3286 25.8844H49.7636L46.4055 16.8476L43.0473 25.8844H39.4823L34.3594 12.0957Z"
      fill="#111111"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.035 25.7077C104.746 26.0299 105.584 26.1896 106.548 26.1896C107.309 26.1896 108.004 26.0876 108.629 25.8851C109.256 25.6648 109.813 25.3604 110.305 24.9716C110.502 24.8091 110.688 24.6302 110.864 24.4381V25.8866H114.012V16.9991C114.012 16.0176 113.767 15.1633 113.276 14.4347C112.801 13.6897 112.133 13.1148 111.27 12.7083C110.407 12.2856 109.282 12.0742 108.148 12.0742H104.954L103.884 14.9165H108.148C108.91 14.9165 109.519 15.119 109.977 15.5255C110.451 15.9142 110.688 16.4049 110.688 16.9976V17.1424L106.576 17.812C105.492 17.9805 104.595 18.2687 103.886 18.6752C103.175 19.0816 102.65 19.5886 102.312 20.1976C101.973 20.7903 101.805 21.4672 101.805 22.2284C101.805 22.9896 102 23.7257 102.389 24.3346C102.777 24.9273 103.327 25.384 104.038 25.7062L104.035 25.7077ZM110.686 19.6847L107.208 20.2759C106.548 20.3941 106.057 20.6055 105.736 20.91C105.414 21.2145 105.254 21.612 105.254 22.1028C105.254 22.5935 105.432 22.9571 105.788 23.2453C106.16 23.5158 106.617 23.6517 107.158 23.6517C107.851 23.6517 108.462 23.5084 108.987 23.2202C109.527 22.9157 109.943 22.5004 110.23 21.9771C110.534 21.4524 110.686 20.8775 110.686 20.2508V19.6847Z"
      fill="#111111"
    />
    <path
      d="M0 7.97766C1.76595 7.97766 2.51066 9.69251 3.2542 11.4047C3.99541 13.1115 4.73547 14.8157 6.48491 14.8157C8.1879 14.8157 8.84124 12.1242 9.51285 9.35719C10.2233 6.43036 10.9543 3.419 12.9698 3.419C14.9303 3.419 15.5713 5.41344 16.2123 7.40783C16.8533 9.40224 17.4942 11.3967 19.4547 11.3967C21.4152 11.3967 22.0562 8.54757 22.6972 5.69845L22.6972 5.6982C23.3382 2.84908 23.9791 0 25.9396 0V6.9733C24.4824 6.9733 23.6367 8.06583 23.1174 9.2088C22.5988 10.3503 22.264 11.8384 21.9529 13.2218L21.9412 13.2736L21.9411 13.2739L21.9405 13.2769L21.9404 13.2772C21.6141 14.7275 21.3099 16.064 20.8646 17.0441C20.413 18.0379 19.958 18.37 19.4547 18.37C18.8093 18.37 18.376 18.0726 17.9893 17.4768C17.5716 16.8332 17.2805 15.9455 16.9508 14.9195L16.9322 14.8617C16.6256 13.9073 16.2787 12.8275 15.7365 11.9922C15.1523 11.0921 14.2849 10.3923 12.9698 10.3923C11.483 10.3923 10.602 11.5299 10.0494 12.7107C9.49279 13.9001 9.11756 15.4462 8.77178 16.8709L8.75908 16.9232C8.41747 18.3306 8.10264 19.6153 7.67875 20.5537C7.23662 21.5325 6.83653 21.789 6.48491 21.789C5.91749 21.789 5.5008 21.5345 5.10418 21.0236C4.67962 20.4766 4.34702 19.7228 3.96567 18.8447L3.94396 18.7947C3.58818 17.9752 3.18332 17.0426 2.6228 16.3219C2.02266 15.5502 1.18931 14.951 0 14.951V7.97766Z"
      fill="#C2AFFF"
    />
    <path
      d="M0 16.5023V23.4757C1.76595 23.4757 2.51066 25.1906 3.2542 26.9027C3.99541 28.6096 4.73547 30.3137 6.48491 30.3137C8.1879 30.3137 8.8412 27.6223 9.51281 24.8553C10.2233 21.9285 10.9543 18.917 12.9698 18.917C14.9303 18.917 15.5713 20.9114 16.2123 22.9058C16.8532 24.9002 17.4942 26.8947 19.4547 26.8947C21.4152 26.8947 22.0562 24.0456 22.6972 21.1965L22.6972 21.1962C23.3382 18.3471 23.9791 15.498 25.9396 15.498V8.52463C25.4364 8.52463 24.9813 8.85664 24.5298 9.8505C24.0845 10.8306 23.7802 12.1671 23.454 13.6174L23.4539 13.6177L23.4532 13.6207L23.4532 13.621L23.4415 13.6728C23.1303 15.0561 22.7956 16.5443 22.277 17.6858C21.7577 18.8288 20.9119 19.9213 19.4547 19.9213C18.1396 19.9213 17.2722 19.2215 16.688 18.3214C16.1459 17.4861 15.799 16.4063 15.4924 15.4519L15.4738 15.3941C15.1441 14.3681 14.853 13.4804 14.4353 12.8368C14.0486 12.241 13.6152 11.9436 12.9698 11.9436C12.4411 11.9436 11.9489 12.3118 11.4545 13.3683C10.9709 14.4017 10.6278 15.8014 10.2666 17.2891L10.2541 17.3407C9.92781 18.6852 9.58271 20.1071 9.09254 21.1923C8.61435 22.251 7.83627 23.3403 6.48491 23.3403C5.30288 23.3403 4.47483 22.7427 3.87875 21.9748C3.32202 21.2577 2.91902 20.3294 2.56444 19.5126L2.54272 19.4626C2.16022 18.5818 1.82597 17.8243 1.39821 17.2743C0.99862 16.7605 0.576648 16.5023 0 16.5023Z"
      fill="#C2AFFF"
    />
  </svg>
);
